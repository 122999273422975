import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ITelegramSlice {
	selectedOperationID: string;
	selectedEmployeeName: string;
	selectedOperationDocumentationLink: string;
	isSelectedOperationHasAnIssue: boolean;
	isSelectedOperationInProcess: boolean;
}

const initialState: ITelegramSlice = {
	selectedOperationID: "",
	selectedEmployeeName: "",
	selectedOperationDocumentationLink: "",
	isSelectedOperationHasAnIssue: false,
	isSelectedOperationInProcess: false,
};

const telegramSlice = createSlice({
	name: "telegram",
	initialState,
	reducers: {
		setSelectedOperationID: (state, action: PayloadAction<string>) => {
			state.selectedOperationID = action.payload;
		},
		setSelectedEmployeeName: (state, action: PayloadAction<string>) => {
			state.selectedEmployeeName = action.payload;
		},
		setSelectedOperationDocumentationLink: (
			state,
			action: PayloadAction<string>
		) => {
			state.selectedOperationDocumentationLink = action.payload;
		},
		setIsSelectedOperationHasAnIssue: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.isSelectedOperationHasAnIssue = action.payload;
		},
		setIsSelectedOperationInProcess: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.isSelectedOperationInProcess = action.payload;
		},
	},
});

export const {
	setSelectedOperationID,
	setSelectedEmployeeName,
	setSelectedOperationDocumentationLink,
	setIsSelectedOperationHasAnIssue,
	setIsSelectedOperationInProcess,
} = telegramSlice.actions;

export default telegramSlice.reducer;
