import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api, loadingMiddleware } from "./api";
import businesProcessesSlice from "./slices/main/businesProcesses.slice";
import telegramProblemSlice from "./slices/telegram/telegramProblem.slice";
import telegramSlice from "./slices/telegram/telegram.slice";
import indicatorsSlice from "./slices/main/indicators.slice";
import operationsModalSlice from "./slices/main/operationsModal.slice";
import loaderSlice from "./slices/main/loader.slice";
import serverErrorModalSlice from "./slices/main/serverErrorModal.slice";
import telegramToWorkSlice from "./slices/telegram/telegramToWork.slice";
import telegramDoneDialogSlice from "./slices/telegram/telegramDone.slice";
import telegramModalSlice from "./slices/telegram/telegramModal.slice";
import objectsSlice from "./slices/main/objects.slice";

const rootReducer = combineReducers({
	// <Main>
	businesProcesses: businesProcessesSlice,
	indicators: indicatorsSlice,
	operationsModal: operationsModalSlice,
	objects: objectsSlice,
	// <Main/>

	// <Shared>
	loader: loaderSlice,
	serverErrorModal: serverErrorModalSlice,
	// <Shared/>

	// <Telegram>
	telegram: telegramSlice,
	telegramModal: telegramModalSlice,
	telegramToWork: telegramToWorkSlice,
	telegramProblem: telegramProblemSlice,
	telegramDone: telegramDoneDialogSlice,
	// <Telegram/>
	[api.reducerPath]: api.reducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefault) =>
		getDefault().concat(api.middleware, loadingMiddleware),
});
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
