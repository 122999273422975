import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Object = {
	Объект: string;
	ВаловаяПрибыль: number;
	ВаловаяПрибыльНаЧеловека: number;
	КЭФОР: number;
	ОбъемПродаж: number;
};

interface IObjectsSlice {
	selectedObjectInstance: string;
	objectsArr: Object[];
}
const initialState: IObjectsSlice = {
	selectedObjectInstance: "",
	objectsArr: [],
};

const objectsSlice = createSlice({
	name: "objects",
	initialState,
	reducers: {
		setSelectedObjectInstance: (state, action: PayloadAction<string>) => {
			state.selectedObjectInstance = action.payload;
		},
		setObjectsArr: (state, action: PayloadAction<Object[]>) => {
			state.objectsArr = action.payload;
		},
	},
});
export const { setSelectedObjectInstance, setObjectsArr } =
	objectsSlice.actions;

export default objectsSlice.reducer;
