import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Middleware } from "@reduxjs/toolkit";
import { setIsLoading } from "./slices/main/loader.slice.ts";
import {
  IAddBusinessProcess,
  IBusinessProcess,
  IUpdateBusinessProcess,
} from "../types/bp.types.ts";
import {
  ICreateOperation,
  ISaveSortedOperations,
  IBaseOperation,
  IUpdateOperation,
  ITempOperationListItem,
  IBranchOperations,
} from "../types/operation.types.ts";
import {
  IEmployee,
  IExecutorList,
  IOperationsNamesList,
  ISetOperationFinished,
  ISetProblemToOperation,
  ITakeOperationToWork,
} from "../types/telegram.types.ts";
import { IServerResponse } from "../types/common.types.ts";
import { IUppObjectsList } from "../types/objects.types.ts";

// Middleware для отслеживания состояния загрузки
// почему то не типизруется (https://github.com/reduxjs/redux-toolkit/issues/368)
export const loadingMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type.endsWith("/pending")) {
    store.dispatch(setIsLoading(true));
  } else if (
    action.type.endsWith("/fulfilled") ||
    action.type.endsWith("/rejected")
  ) {
    store.dispatch(setIsLoading(false));
  }

  return next(action);
};
const { REACT_APP_LOCALHOST } = process.env;

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_LOCALHOST,
  }),
  tagTypes: [
    "Bprocesses",
    "Bp",
    "Reference",
    "Indicators",
    "Operation",
    "Telegram",
  ],

  endpoints: (build) => ({
    getBusinessProcesses: build.query<IBusinessProcess[], void>({
      query: () => `bp`,
      providesTags: ["Bprocesses"],
    }),
    getBusindessProcessBranchOperations: build.query<IBranchOperations, string>(
      {
        query: (_id) => `bp/${_id}`,
      }
    ),
    // getOperationsList - отдает все операции
    getOperationsList: build.query<IBaseOperation[], void>({
      query: () => `operation`,
      providesTags: ["Operation"],
    }),
    getOperationsNames: build.query<IOperationsNamesList, void>({
      query: () => `operation/names`,
      providesTags: ["Operation"],
    }),
    getOperationById: build.query<IBaseOperation, string>({
      query: (_id) => `operation/${_id}`,
      providesTags: ["Operation"],
    }),
    updateOperationById: build.mutation<IServerResponse, IUpdateOperation>({
      query: ({ _id, body }) => ({
        url: `operation/${_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Operation"],
    }),
    updateSortedOperations: build.mutation<
      IServerResponse,
      ISaveSortedOperations
    >({
      query: ({ _id, body }) => ({
        url: `operation/storeSortedOperations/${_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Operation"],
    }),
    // getSortedOperations - отдает операции по текущему БП.
    getSortedOperations: build.query<IBaseOperation[], string>({
      query: (_id) => `operation/operationList/${_id}`,
      providesTags: ["Operation"],
      transformResponse: (response: IBranchOperations | IBranchOperations[]) =>
        Array.isArray(response) ? response[0].operations : response.operations,
    }),
    get1cObjects: build.query<IUppObjectsList, void>({
      query: () => `bp/upp`,
    }),
    addProccess: build.mutation<IServerResponse, IAddBusinessProcess>({
      query: (data) => ({
        url: `bp`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bprocesses"],
    }),
    // <todo>
    deleteProcess: build.mutation<IServerResponse, string>({
      query: (_id) => ({
        url: `bp/${_id}`,
        method: "DELETE",
        body: _id,
      }),
      invalidatesTags: ["Bprocesses"],
    }),
    // </todo>
    updateProcess: build.mutation<IServerResponse, IUpdateBusinessProcess>({
      query: ({ _id, body }) => ({
        url: `bp/${_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Bprocesses"],
    }),
    addOperation: build.mutation<IServerResponse, ICreateOperation>({
      query: (data) => ({
        url: `operation`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Operation"],
    }),
    getEmployeeList: build.query<IEmployee[], void>({
      query: () => `telegram/employee`,
      providesTags: ["Operation", "Telegram"],
    }),
    // <todo>
    getSortedOperationsByValue: build.mutation<
      ITempOperationListItem[],
      { employee: string }
    >({
      query: (employee) => ({
        url: `telegram/getOperations`,
        method: "POST",
        body: employee,
      }),
      invalidatesTags: ["Operation", "Telegram"],
    }),
    // <todo/>
    takeOperationToWork: build.mutation<IServerResponse, ITakeOperationToWork>({
      query: ({ _id, body }) => ({
        url: `telegram/${_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Operation", "Telegram"],
    }),
    setProblemOperation: build.mutation<
      IServerResponse,
      ISetProblemToOperation
    >({
      query: ({ _id, body }) => ({
        url: `telegram/problem/${_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Operation", "Telegram"],
    }),
    setProblemSolved: build.mutation<IServerResponse, string>({
      query: (_id) => ({
        url: `telegram/problemsolved/${_id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Operation", "Telegram"],
    }),
    setOperationFinished: build.mutation<
      IServerResponse,
      ISetOperationFinished
    >({
      query: ({ _id, body }) => ({
        url: `telegram/finishedOperation/${_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Operation", "Telegram"],
    }),
    getProjectRoles: build.query<IExecutorList, void>({
      query: () => `operation/role`,
      providesTags: ["Operation"],
    }),
  }),
});

export const {
  useGetBusinessProcessesQuery,
  useGetBusindessProcessBranchOperationsQuery,
  useGet1cObjectsQuery,
  useGetOperationsListQuery,
  useGetOperationsNamesQuery,
  useDeleteProcessMutation,
  useUpdateProcessMutation,
  useAddProccessMutation,
  useAddOperationMutation,
  useGetSortedOperationsQuery,
  useGetEmployeeListQuery,
  useGetProjectRolesQuery,
  useGetOperationByIdQuery,
  useUpdateOperationByIdMutation,
  useGetSortedOperationsByValueMutation,
  useTakeOperationToWorkMutation,
  useSetProblemOperationMutation,
  useSetProblemSolvedMutation,
  useSetOperationFinishedMutation,
  useUpdateSortedOperationsMutation,
} = api;
