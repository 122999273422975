import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

interface ITelegramToWorkSlice {
	calendarDialogOpen: boolean;
	selectedDate: string;
}

const initialState: ITelegramToWorkSlice = {
	calendarDialogOpen: false,
	selectedDate: moment().format("DD.MM.YYYY HH:mm"),
};

const telegramToWorkSlice = createSlice({
	name: "telegramToWork",
	initialState,
	reducers: {
		setCalendarDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.calendarDialogOpen = action.payload;
		},
		setSelectedDate: (state, action: PayloadAction<string>) => {
			state.selectedDate = action.payload;
		},
	},
});

export const { setCalendarDialogOpen, setSelectedDate } =
	telegramToWorkSlice.actions;

export default telegramToWorkSlice.reducer;
