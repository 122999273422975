import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IBusinessProcessesSlice {
	selectedBusinessProcessID: string;
	bpArr: any[];
	bpTitle: string;
	actionStatusBP: "EDIT" | "CREATE" | "CREATE_SUB" | "NONE";
}

const initialState: IBusinessProcessesSlice = {
	selectedBusinessProcessID: "",
	bpArr: [],
	bpTitle: "",
	actionStatusBP: "NONE",
};

const businesProcessesSlice = createSlice({
	name: "businesProcesses",
	initialState,
	reducers: {
		setBpTitle: (state, action: PayloadAction<string>) => {
			state.bpTitle = action.payload;
		},
		setBpArr: (state, action: PayloadAction<never[]>) => {
			state.bpArr = [...action.payload];
		},
		setSelectedBusinessProcessID: (state, action: PayloadAction<string>) => {
			state.selectedBusinessProcessID = action.payload;
		},
		setActionStatusBP: (
			state,
			action: PayloadAction<IBusinessProcessesSlice["actionStatusBP"]>
		) => {
			state.actionStatusBP = action.payload;
		},
	},
});

export const {
	setBpTitle,
	setBpArr,
	setSelectedBusinessProcessID,
	setActionStatusBP,
} = businesProcessesSlice.actions;

export default businesProcessesSlice.reducer;
