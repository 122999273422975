import { Backdrop, CircularProgress } from "@mui/material";
import { useAppSelector } from "../store/hooks";

const Loader = () => {
	const { isLoading } = useAppSelector((store) => store.loader);
	return (
		<Backdrop open={isLoading} sx={{ zIndex: "9999" }}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};

export default Loader;
