import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IOperationsModal {
  // <Operation>
  selectedOperationID: string;
  // <Operation />

  // <Form>
  title: string;
  executor: string;
  link: string;
  complianceWithMilestones: number;
  maxEfficiencyPercentage: number;
  minFinancialBurden: number;
  minCostExpenses: number;
  fasterAndEarlierInterestAccrual: number;
  evenWorkerLoad: number;
  minWaitTimeForNextOperations: number;
  isRepeated: boolean;
  predecessorId: string;
  // <Form />

  // <Modal>
  modalIsOpen: boolean;
  modalType: "create" | "edit" | "";
  editingOperationId: string;
  // <Modal />
}

type FormField = keyof IOperationsModal;

const initialState: IOperationsModal = {
  // <Operation>
  selectedOperationID: "",
  // <Operation />

  // <Form>
  title: "",
  executor: "",
  isRepeated: true,
  link: "",
  predecessorId: "",
  complianceWithMilestones: 1,
  maxEfficiencyPercentage: 1,
  minFinancialBurden: 1,
  minCostExpenses: 1,
  fasterAndEarlierInterestAccrual: 1,
  evenWorkerLoad: 1,
  minWaitTimeForNextOperations: 1,
  // <Form />

  // <Modal>
  editingOperationId: "",
  modalIsOpen: false,
  modalType: "create",
  // <Modal />
};

const operationsModalSlice = createSlice({
  name: "operationsModal",
  initialState,
  reducers: {
    setSelectedOperationID: (state, action: PayloadAction<string>) => {
      state.selectedOperationID = action.payload;
    },
    setModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.modalIsOpen = action.payload;
    },
    setModalType: (
      state,
      action: PayloadAction<IOperationsModal["modalType"]>
    ) => {
      state.modalType = action.payload;
    },
    resetModalForm: (state) => {
      return {
        ...state,
        title: "",
        executor: "",
        isRepeated: true,
        link: "",
        predecessorId: "",
        complianceWithMilestones: 1,
        maxEfficiencyPercentage: 1,
        minFinancialBurden: 1,
        minCostExpenses: 1,
        fasterAndEarlierInterestAccrual: 1,
        evenWorkerLoad: 1,
        minWaitTimeForNextOperations: 1,
      };
    },
    setMultipleFields: (state, action: PayloadAction<IOperationsModal>) => {
      return { ...state, ...action.payload };
    },
    setSomeFormField: (
      state,
      action: PayloadAction<{
        field: FormField;
        value: IOperationsModal[FormField];
      }>
    ) => {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    },
    setEditingOperationId: (state, action: PayloadAction<string>) => {
      state.editingOperationId = action.payload;
    },
  },
});

export const {
  setModalIsOpen,
  setModalType,
  resetModalForm,
  setMultipleFields,
  setSomeFormField,
  setEditingOperationId,
  setSelectedOperationID,
} = operationsModalSlice.actions;

export default operationsModalSlice.reducer;
