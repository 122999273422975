import "./App.css";
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Container } from "@mui/material";
import Loader from "./shared/Loader";
import { ServerErrorModal } from "./shared/ServerErrorModal";
import { Toaster } from "react-hot-toast";
const tg = (window as any).Telegram.WebApp;
tg.expand();
const Main = lazy(() => import("./Main/Main"));
const Telegram = lazy(() => import("./Telegram/Telegram"));

const App = () => {
  React.useEffect(() => {
    tg.ready();
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Container
        maxWidth={false}
        className="App"
        sx={{ minHeight: "100vh", width: "100%" }}
      >
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/telegram" element={<Telegram />} />
        </Routes>
        <ServerErrorModal />
        <Loader />
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 3000,
            style: { fontFamily: "Roboto, sans-serif" },
            error: {
              duration: 5000,
            },
          }}
        />
      </Container>
    </Suspense>
  );
};

export default App;
