import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {setIsOpen} from "../store/slices/main/serverErrorModal.slice.ts";

export const ServerErrorModal = () => {
	const dispatch = useAppDispatch();
	const { isOpen } = useAppSelector((store) => store.serverErrorModal);

	return (
		<Dialog open={isOpen} onClose={() => dispatch(setIsOpen(false))}>
			<DialogTitle>Ошибка сервера</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Произошла непредвиденная ошибка на стороне сервера. Ваши изменения не
					были сохранены!
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => dispatch(setIsOpen(false))} autoFocus>
					Понятно
				</Button>
			</DialogActions>
		</Dialog>
	);
};
