import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ITelegramProblemSlice {
	problemDialogOpen: boolean;
	issueDescription: string;
	issueProposedSolution: string;
	issueAttention: string;
	issueFormValid: boolean;
}

const initialState: ITelegramProblemSlice = {
	problemDialogOpen: false,
	issueDescription: "",
	issueProposedSolution: "",
	issueAttention: "",
	issueFormValid: false,
};

const telegramSliceProblem = createSlice({
	name: "telegramProblem",
	initialState,
	reducers: {
		setProblemDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.problemDialogOpen = action.payload;
		},
		setIssueDescription: (state, action: PayloadAction<string>) => {
			state.issueDescription = action.payload;
		},
		setIssueProposedSolution: (state, action: PayloadAction<string>) => {
			state.issueProposedSolution = action.payload;
		},
		setIssueAttention: (state, action: PayloadAction<string>) => {
			state.issueAttention = action.payload;
		},
		setIssueFormValid: (state, action: PayloadAction<boolean>) => {
			state.issueFormValid = action.payload;
		},
		setMultipleFieldsTelegramProblemForm: (
			state,
			action: PayloadAction<ITelegramProblemSlice>
		) => {
			return { ...state, ...action.payload };
		},
	},
});

export const {
	setProblemDialogOpen,
	setIssueDescription,
	setIssueProposedSolution,
	setIssueAttention,
	setIssueFormValid,
	setMultipleFieldsTelegramProblemForm,
} = telegramSliceProblem.actions;

export default telegramSliceProblem.reducer;
