import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IIndicatorsSlice {
  indicatorType:
    | "Соблюдение контрольных вех, в том числе по договору."
    | "Максимальный процент процентования ВП (ускорение процентования ВП, влево, принцип 50/5 и 80/20)."
    | "Минимальная финансовая нагрузка ИЛИ максимальная финансовая эффективность."
    | "Минимальная себестоимость/затраты."
    | "Большее и более раннее запроцентование."
    | "Равномерная нагрузка на рабочих."
    | "Минимальное время ожидания следующих операций."
    | "";
  indicatorValue: number;
}
const initialState: IIndicatorsSlice = {
  indicatorType: "",
  indicatorValue: 0,
};

const indicatorsSlice = createSlice({
  name: "indicators",
  initialState,
  reducers: {
    setIndicatorType: (
      state,
      action: PayloadAction<IIndicatorsSlice["indicatorType"]>
    ) => {
      state.indicatorType = action.payload;
    },
    setIndicatorValue: (state, action: PayloadAction<number>) => {
      state.indicatorValue = action.payload;
    },
  },
});

export const { setIndicatorType, setIndicatorValue } = indicatorsSlice.actions;

export default indicatorsSlice.reducer;
