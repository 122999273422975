import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IServerErrorModal {
	isOpen: boolean;
}
const initialState: IServerErrorModal = {
	isOpen: false,
};

const serverErrorModalSlice = createSlice({
	name: "serverErrorModal",
	initialState,
	reducers: {
		setIsOpen: (state, action: PayloadAction<boolean>) => {
			state.isOpen = action.payload;
		},
	},
});
export const { setIsOpen } = serverErrorModalSlice.actions;

export default serverErrorModalSlice.reducer;
