import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ITelegramModalSlice {
	isOpenModal: boolean;
}

const initialState: ITelegramModalSlice = {
	isOpenModal: false,
};

const telegramModalSlice = createSlice({
	name: "telegramModal",
	initialState,
	reducers: {
		setIsOpenModal: (state, action: PayloadAction<boolean>) => {
			state.isOpenModal = action.payload;
		},
	},
});

export const { setIsOpenModal } = telegramModalSlice.actions;

export default telegramModalSlice.reducer;
