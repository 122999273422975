import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ITelegramDoneSlice {
	veha: boolean;
	completionDialogOpen: boolean;
	timeSpentToFinished: string;
}

const initialState: ITelegramDoneSlice = {
	veha: false,
	completionDialogOpen: false,
	timeSpentToFinished: "",
};

const telegramDoneSlice = createSlice({
	name: "telegramDone",
	initialState,
	reducers: {
		setVeha: (state, action: PayloadAction<boolean>) => {
			state.veha = action.payload;
		},
		setCompletionDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.completionDialogOpen = action.payload;
		},
		setTimeSpentToFinished: (state, action: PayloadAction<string>) => {
			state.timeSpentToFinished = action.payload;
		},
	},
});

export const { setVeha, setCompletionDialogOpen, setTimeSpentToFinished } =
	telegramDoneSlice.actions;

export default telegramDoneSlice.reducer;
